// import type React from "react";
// import { useState } from "react";
// import { Container, Form, Button, Table, Alert, Modal, Row, Col } from "react-bootstrap";
// import "bootstrap/dist/css/bootstrap.min.css";
// import PageTitle from "../../../components/PageTitle";
// import api from "../../../utils/ApiMethod";
// import { useSelector } from "react-redux";
// import { RootState } from "../../../redux/store";

// interface StudentData {
//   name: string;
//   fatherName: string;
//   aadharNumber: string;
//   studentId: string;
//   studentName: string;
//   selectedInTeam: string[];
// }

// type SearchType = "aadhar" | "studentId";

// const StudentSearch: React.FC = () => {
//   const [searchId, setSearchId] = useState("");
//   const [studentData, setStudentData] = useState<StudentData[]>([]); // Updated type
//   const [isLoading, setIsLoading] = useState(false);
//   const [error, setError] = useState("");
//   const [showDeleteModal, setShowDeleteModal] = useState(false);
//   const [isDeleting, setIsDeleting] = useState(false);
//   const [searchType, setSearchType] = useState<SearchType>("aadhar");

//   const { user, userLoggedIn, loading, userId } = useSelector(
//     (state: RootState) => ({
//       user: state.Auth.user,
//       loading: state.Auth.loading,
//       error: state.Auth.error,
//       userLoggedIn: state.Auth.userLoggedIn,
//       userId: state.Auth._id,
//     })
//   );
//   let profileId = user._id ? user._id : user.user ? user.user._id : "";
//   const Key_Key = user.moniterd_ttl
//     ? user.moniterd_ttl
//     : user.user
//     ? user.user.moniterd_ttl
//     : "";

//   const handleSearch = async () => {
//     setIsLoading(true);
//     setError("");
//     setStudentData([]);

//     try {
//       const response = await api.fetchDataAuth(
//         `/get/adharorstudentid/${searchId}/${searchType}/${profileId}/${Key_Key}`
//       );
//       if (response.status === 200 || response.status === 201) {
//         setStudentData(response?.data?.data); // Assuming response data has a 'data' field
//       } else {
//         if (response.status === 440) {
//           alert("Session Expired");
//         }
//         if (response.status === 429) {
//           setError("Too many requests from this IP");
//         }
//         if (response.status === 400 || response.status === 404) {
//           setError(response.message);
//         }
//       }
//     } catch (err) {
//       setError(
//         "An error occurred while fetching student data. Please try again."
//       );
//     } finally {
//       setIsLoading(false);
//     }
//   };

//   const handleDelete = async (studentId: string) => {
//     // Accept studentId as parameter
//     setIsDeleting(true);
//     try {

//       if(5==5){
//         alert("check url")
//         return
//       }
//       const response = await api.deleteData(
//         `/delete/student/${searchId}/${searchType}/${profileId}/${Key_Key}`
//       );
//       if (response.status === 200 || response.status === 201) {
//         setStudentData([]); // Clear student data after deletion
//         setSearchId("");
//         setShowDeleteModal(false);
//       } else {
//         if (response.status === 440) {
//           alert("Session Expired");
//         }
//         if (response.status === 429) {
//           setError("Too many requests from this IP");
//         }
//         if (response.status === 400 || response.status === 404) {
//           setError(response.message);
//         }
//       }
//     } catch (err) {
//       setError(
//         "An error occurred while deleting student data. Please try again."
//       );
//     } finally {
//       setIsDeleting(false);
//     }
//   };

//   return (
//     <Container className="py-5">
//       <PageTitle
//         breadCrumbItems={[
//           { label: "Delete Players", path: "/deleteplayer" },
//           { label: "Delete Players", path: "/deleteplayer", active: true },
//         ]}
//         title="Delete Players"
//       />
//       <h1 className="text-center mb-4">Student Information Search</h1>
//       <Form className="mb-4">
//         <Form.Group className="mb-3">
//           <Form.Label>Search Type</Form.Label>
//           <div>
//             <Form.Check
//               inline
//               type="radio"
//               label="Aadhaar"
//               name="searchType"
//               id="aadhar"
//               checked={searchType === "aadhar"}
//               onChange={() => setSearchType("aadhar")}
//             />
//             <Form.Check
//               inline
//               type="radio"
//               label="Student ID"
//               name="searchType"
//               id="student"
//               checked={searchType === "studentId"}
//               onChange={() => setSearchType("studentId")}
//             />
//           </div>
//         </Form.Group>
//         <Row>
//           <Col className="mb-8">
//           <Form.Group className="mb-3" controlId="searchId">
//           <Form.Label>Enter Aadhaar Number or Student ID</Form.Label>
//           <Form.Control
//             type="text"
//             placeholder="Enter Aadhaar Number or Student ID"
//             value={searchId}
//             onChange={(e) => setSearchId(e.target.value)}
//           />
//         </Form.Group>
//           </Col>
//           <Col className="mb-4">
//           <Button variant="primary" onClick={handleSearch} disabled={isLoading}>
//           {isLoading ? "Searching..." : "Search"}
//         </Button>
//           </Col>

//         </Row>

//       </Form>

//       {error && <Alert variant="danger">{error}</Alert>}

//       {studentData.length > 0 && (
//         <div>
//           <h2 className="mb-3">Student Information</h2>
//           <Table striped bordered hover responsive>
//             <thead>
//               <tr>
//                 <th>Student Name</th>
//                 <th>Father's Name</th>
//                 <th>Aadhaar Number</th>
//                 <th>Student ID</th>
//               </tr>
//             </thead>
//             <tbody>
//               {studentData.map((student) => (
//                 <tr key={student.aadharNumber}>
//                   <td>{student.studentName}</td>
//                   <td>{student.fatherName}</td>
//                   <td>{student.aadharNumber}</td>
//                   <td>{student.studentId}</td>
//                 </tr>
//               ))}
//             </tbody>
//           </Table>

//           {studentData.some((student) => student.selectedInTeam.length > 0) && (
//             <div className="mt-4">
//               <h3>Selected in Teams</h3>
//               <ul className="list-group">
//                 {studentData.map(
//                   (student, index) =>
//                     student.selectedInTeam.length > 0 && (
//                       <div key={index}>
//                         <h5>{student.name}</h5>
//                         <ul className="list-group">
//                           {student.selectedInTeam.map((team, teamIndex) => (
//                             <li key={teamIndex} className="list-group-item">
//                               {team}
//                             </li>
//                           ))}
//                         </ul>
//                       </div>
//                     )
//                 )}
//               </ul>
//             </div>
//           )}

//           <Button
//             variant="danger"
//             className="mt-4"
//             onClick={() => setShowDeleteModal(true)}
//           >
//             Delete Student Data
//           </Button>
//         </div>
//       )}

//       <Modal show={showDeleteModal} onHide={() => setShowDeleteModal(false)}>
//         <Modal.Header closeButton>
//           <Modal.Title>Confirm Deletion</Modal.Title>
//         </Modal.Header>
//         <Modal.Body>
//           Are you sure you want to delete this student's data? This action
//           cannot be undone.
//         </Modal.Body>
//         <Modal.Footer>
//           <Button variant="secondary" onClick={() => setShowDeleteModal(false)}>
//             Cancel
//           </Button>
//           <Button
//             variant="danger"
//             onClick={() => handleDelete(studentData[0].studentId)}
//             disabled={isDeleting}
//           >
//             {isDeleting ? "Deleting..." : "Delete"}
//           </Button>
//         </Modal.Footer>
//       </Modal>
//     </Container>
//   );
// };

// export default StudentSearch;

import type React from "react";
import { useState } from "react";
import {
  Container,
  Form,
  Button,
  Table,
  Alert,
  Modal,
  Row,
  Col,
} from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import PageTitle from "../../../components/PageTitle";
import api from "../../../utils/ApiMethod";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import { StringLiteral } from "typescript";

interface StudentData {
  name: string;
  fatherName: string;
  aadharNumber: string;
  studentId: string;
  studentName: string;
  selectedInTeam: string[];
  _id: string;
  Udise_Code:string;
}

type SearchType = "aadhar" | "studentId";

const StudentSearch: React.FC = () => {
  const [searchId, setSearchId] = useState("");
  const [studentData, setStudentData] = useState<StudentData[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  // const [isDeleting, setIsDeleting] = useState(false);
  const [isDeleting, setIsDeleting] = useState<{
    [key: string]: boolean;
  }>({});
  const [searchType, setSearchType] = useState<SearchType>("studentId");
  // const [selectedStudents, setSelectedStudents] = useState<string[]>([]); // New state for selected students
  const [selectedStudents, setSelectedStudents] = useState<string>(""); // New state for selected students

  const { user } = useSelector((state: RootState) => ({
    user: state.Auth.user,
  }));

  let profileId = user._id ? user._id : user.user ? user.user._id : "";
  const Key_Key = user.moniterd_ttl
    ? user.moniterd_ttl
    : user.user
    ? user.user.moniterd_ttl
    : "";

  const handleSearchTypeChange = (type: SearchType) => {
    setSearchType(type);
  };

  const handleSearch = async () => {
    setIsLoading(true);
    setError("");
    setStudentData([]);
    try {
      const response = await api.fetchDataAuth(
        `/get/adharorstudentid/${searchId}/${searchType}/${profileId}/${Key_Key}`
      );
      if (response.status === 200 || response.status === 201) {
        setStudentData(response?.data?.data); // Assuming response data has a 'data' field
      } else {
        if (response.status === 440) {
          alert("Session Expired");
        }
        if (response.status === 429) {
          setError("Too many requests from this IP");
        }
        if (response.status === 400 || response.status === 404) {
          setError(response.message);
        }
      }
    } catch (err) {
      setError(
        "An error occurred while fetching student data. Please try again."
      );
    } finally {
      setIsLoading(false);
    }
  };
  const handleApiError = (response: any) => {
    if (response.status === 440) {
      alert("Session Expired");
    }
    if (response.status === 429) {
      setError("Too many requests from this IP");
    }
    if (response.status === 400 || response.status === 404) {
      setError(response.message);
    }
  };
  const handleDelete = async (studentId: string, studentName: String) => {
    if (!selectedStudents.length) return;

    setError("")


    // if (studentId){
    //   alert(`Deleting ${studentName}`)
    //   return
    // }
    const isConfirmed = window.confirm(`Deleting ${studentName}`);
    if (!isConfirmed) {
      return;
    }
    setIsDeleting((prevState) => ({ ...prevState, [studentId]: true }));
    try {
      // const response = await api.deleteData(
      //   `/delete/student/${studentId}/${profileId}/${Key_Key}`
      // );
       const response = await api.updatePatchData(
        `/delete/student/${studentId}/${profileId}/${Key_Key}`,{}
      );
      if (response.status === 200 || response.status === 201) {
        setStudentData([]);
        setSearchId("")
        setError("")
        alert(`${studentName} successfully deleted`)
        setSelectedStudents("");
        setShowDeleteModal(false);
      } else {
        handleApiError(response);
      }
    } catch (err) {
      setError(
        "An error occurred while deleting student data. Please try again."
      );
    } finally {
      setIsDeleting((prevState) => ({ ...prevState, [studentId]: false }));
      // setIsDeleting(false);
    }
  };
  // const handleSelectStudent = (studentId: string) => {
  //   setSelectedStudents((prev) =>
  //     prev.includes(studentId)
  //       ? prev.filter((id) => id !== studentId)
  //       : [...prev, studentId]
  //   );
  // };
  const handleSelectStudent = (studentId: string) => {
    setSelectedStudents(studentId); // Ensure only one selected at a time
  };
  return (
    <Container className="">
      <PageTitle
        breadCrumbItems={[
          { label: "Delete Players", path: "/deleteplayer" },
          // { label: "Delete Players", path: "/deleteplayer", active: true },
        ]}
        title="Delete Players"
      />
      <h1 className="text-center mb-4">Student Information Search</h1>
      <Form className="mb-4">
        <Form.Group className="mb-3">
          <Form.Label>Search Type</Form.Label>
          <div>
            <Form.Check
              inline
              type="radio"
              label="Student ID"
              name="searchType"
              id="student"
              checked={searchType === "studentId"}
              // onChange={() => setSearchType("studentId")}
              onChange={() => handleSearchTypeChange("studentId")}
            />

            <Form.Check
              inline
              type="radio"
              label="Aadhaar"
              name="searchType"
              id="aadhar"
              checked={searchType === "aadhar"}
              // onChange={() => setSearchType("aadhar")}
              onChange={() => handleSearchTypeChange("aadhar")}
            />
          </div>
        </Form.Group>
        <Row>
          <Col className="mb-8">
            <Form.Group className="mb-3" controlId="searchId">
              <Form.Label>Enter Aadhaar Number or Student ID</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter Aadhaar Number or Student ID"
                value={searchId}
                onChange={(e) => setSearchId(e.target.value)}
              />
            </Form.Group>
          </Col>
          <Col className="mb-4">
            <Button
              variant="primary"
              onClick={handleSearch}
              disabled={isLoading}
            >
              {isLoading ? "Searching..." : "Search"}
            </Button>
          </Col>
        </Row>
      </Form>

      {error && <Alert variant="danger">{error}</Alert>}

      {studentData.length > 0 && (
        <div>
          <h2 className="mb-3">Student Information</h2>
          <Table striped bordered hover responsive>
            <thead>
              <tr>
                <th>Select</th>
                <th>Student Name</th>
                <th>Udise Code</th>
                <th>Father's Name</th>
                <th>Aadhaar Number</th>
                <th>Student ID</th>
                <th>Selected in Teams</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {studentData.map((student) => (
                <tr key={student._id}>
                  <td>
                    <Form.Check
                      type="radio"
                      name="selectedStudent" // Ensures only one student can be selected at a time
                      checked={selectedStudents.includes(student._id)}
                      onChange={() => handleSelectStudent(student._id)}
                    />
                  </td>
                  <td>{student?.studentName}</td>
                  <td>{student?.Udise_Code}</td>
                  <td>{student?.fatherName}</td>
                  <td>{student?.aadharNumber}</td>
                  <td>{student?.studentId}</td>
                  <td>
                    {/* <div className="mt-0"> */}
                    <ul className="list-group">
                      {student.selectedInTeam.length > 0 && (
                        <ul className="list-group">
                          {student.selectedInTeam.map((team) => (
                            <li key={student?._id} className="list-group-item">
                              {team}
                            </li>
                          ))}
                        </ul>
                      )}
                    </ul>
                    {/* </div> */}
                  </td>
                  <td>
                    <Button
                      variant="danger"
                      className="mt-4"
                      onClick={() =>
                        handleDelete(student?._id, student?.studentName)
                      }
                      disabled={
                        isDeleting[student?._id || ""] ||
                        student?._id !== selectedStudents
                      }
                    >
                      {isDeleting[student?._id || ""]
                        ? "Deleting........."
                        : "Delete"}
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      )}

      {/* <Modal show={showDeleteModal} onHide={() => setShowDeleteModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Confirm Deletion</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure you want to delete the selected student's data? This
          action cannot be undone.
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowDeleteModal(false)}>
            Cancel
          </Button>
          <Button variant="danger" onClick={handleDelete} disabled={isDeleting}>
            {isDeleting ? "Deleting..." : "Delete"}
          </Button>
        </Modal.Footer>
      </Modal> */}
    </Container>
  );
};

export default StudentSearch;
