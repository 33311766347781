import { useEffect, useState } from "react";
import { Button, Card, Col, Dropdown, Form, Row } from "react-bootstrap";
import api from "../../../../utils/ApiMethod";
import PageTitle from "../../../../components/PageTitle";
import Spinner from "../../../../components/Spinner";
import Table from "../../../../components/Table";
import UpdateCrcModal from "./UpdateCrcModal";
type RowIdType = string | number; // Define a type alias for row IDs
const AllCRC = () => {
  const [data, setData] = useState<any>();
  // console.log("vvvvvvvvvvvv9876", data);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const functionToGetAllSchoolLevelData = async () => {
    setLoading(true);
    setError(null);
    try {
      const response = await api.fetchDataAuth(`/get/all/crc/list`);
      console.log("data", response);
      setData(response?.data?.clusters);
    } catch (error) {
      console.error("Error fetching data:", error);
      setError("Failed to fetch details. Please try again later.");
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    functionToGetAllSchoolLevelData();
  }, []);

  const columns = [
    {
      Header: "S.NO",
      accessor: "index",
      Cell: ({ row }: { row: any }) => row.index + 1,
      sort: true,
    },
    {
      Header: "CRC Name",
      accessor: "region_name",
      sort: true,
    },
    {
      Header: "Block Name Code",
      accessor: "parent_region",
      Cell: ({ row }:{row:any}) => row?.values?.parent_region?.region_name || "",
    //   Cell: ({ row }: { row: any }) =>
    //     row?.parent_region ? row?.parent_region?.region_name : "",
      sort: true,
    },
    // {
    //   Header: "Student Name",
    //   accessor: "studentName",
    //   sort: true,
    // },
    // {
    //   Header: "Aadhar Number",
    //   accessor: "aadharNumber",
    //   sort: true,
    // },

    // {
    //   Header: "Details",
    //   accessor: "a",
    //   Cell: () => (
    //     <a href={`/details/1`}>
    //       <i className="uil uil-eye"></i>
    //     </a>
    //   ),
    //   sort: false,
    // },
    {
      Header: "Action",
      accessor: "b",
      Cell: ({row} : {row:any}) => (
        // <a href={`/edit/2`}>
        //   <i className="uil uil-edit"></i>
        // </a>
        <UpdateCrcModal data={row.original}/>
      ),
      sort: false,
    },
  ];

  const sizePerPageList = [
    { text: "10", value: 10 },
    { text: "25", value: 25 },
    { text: "50", value: 50 },
    { text: "All", value: data?.length },
  ];

  return (
    <>
      <PageTitle
        breadCrumbItems={[
          { label: "All CRC", path: "/all-crc/list" },
          {
            label: "All CRC list",
            path: "/all-crc/list",
            active: true,
          },
        ]}
        title={"All CRC list"}
      />
      <Row>
        <Col>
          <Card>
            <Card.Body>
              {loading ? (
                <div style={{ textAlign: "center" }}>
                  <Spinner />
                </div>
              ) : (
                <Table
                  columns={columns}
                  data={data ? data : []}
                  pageSize={10}
                  sizePerPageList={sizePerPageList}
                  isSortable={true}
                  pagination={true}
                  isSearchable={true}
                />
              )}
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default AllCRC;
