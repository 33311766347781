import React, { useEffect, useState } from "react";
import { downloadExcel } from "react-export-table-to-excel";
import {
  Table,
  Pagination,
  Spinner,
  Form,
  Card,
  Container,
  Col,
  Button,
  Row,
} from "react-bootstrap";
import api from "../../../../utils/ApiMethod";
import PageTitle from "../../../../components/PageTitle";

const RegisteredPlayers = () => {
  const [students, setStudents] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);
  const [totalPages, setTotalPages] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [limit] = useState(50); // Show 10 students per page
  const [searchTerm, setSearchTerm] = useState(""); // State to store search term

  const [exporting, setExporting] = useState(false);
  const [districts, setDistricts] = useState<any>([]);

  // Fetch all districts
  const fetchAllDistrict = async () => {
    setLoading(true);
    try {
      const response = await api.fetchData(`/all/districts`);
      setDistricts(response.data.districts);
    } catch (error) {
      console.error("Error fetching districts:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchAllDistrict();
  }, []);

  // Fetch students data from the API with optional search query
  const fetchStudents = async (page: number, searchQuery: string = "") => {
    setLoading(true);
    try {
      const response = await api.fetchData(
        `/api/students?page=${page}&limit=${limit}&search=${searchQuery}`
      );
      setStudents(response.data.students);
      setTotalPages(response.data.totalPages);
      setCurrentPage(response.data.currentPage);
    } catch (error) {
      console.error("Error fetching students:", error);
    } finally {
      setLoading(false);
    }
  };

  // Update the data when the currentPage or searchTerm changes
  useEffect(() => {
    fetchStudents(currentPage, searchTerm);
  }, [currentPage, searchTerm]);

  // Handle the search input change
  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value); // Update search term on user input
    setCurrentPage(1); // Reset to first page when search term changes
  };

  // Header for export data
  const header = [
    "Sl.no",
    "Udise_Code",
    "Student Name",
    "Student Id",
    "CRC_Name_Code",
    "Father's Name",
    "Mother's Name",
    "Email",
    "Mobile No",
    "Gender",
    "Age Category",
  ];

  // Handle export action
  const handleExport = async (searchQuery: string = "") => {
    setExporting(true);
    try {
      const response = await api.fetchData(
        `/api/students?search=${searchQuery}&export=true`
      );
      if (response.status === 200) {
        const exportData = response.data?.students || [];
        const recordForBody = exportData.map((body: any, index: number) => [
          index + 1,
          body?.Udise_Code,
          body?.studentName,
          body?.studentId,
          body?.clusterName,
          body?.fatherName,
          body?.motherName,
          body?.email,
          body?.mobileNumber,
          body?.gender,
          body?.ageCategory,
        ]);

        downloadExcel({
          fileName: searchQuery
            ? `Registered Students of District ${searchQuery}`
            : `Registered Students`, // Name of excel file
          sheet: "Student Details", // Name of sheet in downloaded excel file
          tablePayload: {
            header,
            body: recordForBody,
          },
        });
      } else {
        alert("No data found for export.");
      }
    } catch (error) {
      console.error("Error exporting data:", error);
    } finally {
      setExporting(false);
    }
  };

  return (
    <div>
      <PageTitle
        breadCrumbItems={[
          { label: "Registered Players", path: "/school/list" },
          {
            label: "Registered Players",
            path: "/registered/Player",
            active: true,
          },
        ]}
        title="Registered Players"
      />
      <Card>
        <Container>
          <Container className="mb-2 mt-2">
            <Row className="align-items-center">
              {/* Search Form Column */}
              <Col sm={6} md={8}>
                <Form>
                  <Form.Group controlId="search">
                    <Form.Label>Search Students</Form.Label>
                    <Form.Control
                      as="select"
                      value={searchTerm}
                      onChange={handleSearchChange}
                    >
                      <option value="">Select District</option>
                      {districts.map((dist: any) => (
                        <option key={dist?._id} value={dist?.region_name}>
                          {dist?.region_name}
                        </option>
                      ))}
                    </Form.Control>
                  </Form.Group>
                </Form>
              </Col>

              {/* Export Button Column */}
              <Col sm={6} md={4} className="text-right">
                <Button
                  variant="primary"
                  className="mt-3"
                  onClick={() => handleExport(searchTerm)}
                >
                  {exporting ? "Exporting Data..." : "Export Data"}
                </Button>
                <Button
                  variant="primary"
                  className="mt-3"
                  disabled={!searchTerm}
                  style={{ marginLeft: "3rem" }}
                  onClick={() => {
                    setSearchTerm("");
                  }}
                >
                  Clear Filter
                </Button>
              </Col>
            </Row>
          </Container>

          {loading ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "50vh", // This makes sure the loader is centered vertically and horizontally
              }}
            >
              <Spinner animation="border" />
            </div>
          ) : (
            <>
              <Table striped bordered hover responsive>
                <thead>
                  <tr>
                    <th>Sl.No</th>
                    <th>Student Name</th>
                    <th>Udise Code</th>
                    <th>Phone Number</th>
                    <th>Email</th>
                    <th>Age Category</th>
                  </tr>
                </thead>
                <tbody>
                  {students.map((student: any, index) => (
                    <tr key={student._id}>
                      <td>{index + 1}</td>
                      <td>{student?.studentName}</td>
                      <td>{student?.Udise_Code}</td>
                      <td>{student?.mobileNumber}</td>
                      <td>{student?.email}</td>
                      <td>{student?.ageCategory}</td>
                    </tr>
                  ))}
                </tbody>
              </Table>

              <Pagination>
                <Pagination.Prev
                  onClick={() =>
                    setCurrentPage((prev) => Math.max(prev - 1, 1))
                  }
                  disabled={currentPage === 1}
                />
                {[...Array(totalPages)].map((_, index) => (
                  <Pagination.Item
                    key={index + 1}
                    active={index + 1 === currentPage}
                    onClick={() => setCurrentPage(index + 1)}
                  >
                    {index + 1}
                  </Pagination.Item>
                ))}
                <Pagination.Next
                  onClick={() =>
                    setCurrentPage((prev) => Math.min(prev + 1, totalPages))
                  }
                  disabled={currentPage === totalPages}
                />
              </Pagination>
            </>
          )}
        </Container>
      </Card>
    </div>
  );
};

export default RegisteredPlayers;
