import { useEffect, useState } from "react";
import api from "../../../../utils/ApiMethod";
import PageTitle from "../../../../components/PageTitle";
import {
  Button,
  Card,
  Col,
  Form,
  FormControl,
  FormGroup,
  FormLabel,
  Row,
} from "react-bootstrap";
import Table from "../../../../components/Table";
import QueryDetailsModal from "./QueryDetailsModal";
import Spinner from "../../../../components/Spinner";

type RowIdType = string | number; // Define a type alias for row IDs
const AllQuery = () => {
  const [data, setData] = useState<any>();
  const [selectedLevel, setSelectedLevel] = useState("");
  const [selectedStatusOfQuery, setSelectedStatusOfQuery] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const functionToGetAllSchoolLevelData = async () => {
    setLoading(true);
    setError(null);
    try {
      const response = await api.fetchDataAuth(`/api/query`);
      // console.log("data", response?.data?.data);
      setData(response?.data?.data);
    } catch (error) {
      console.error("Error fetching data:", error);
      setError("Failed to fetch details. Please try again later.");
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    functionToGetAllSchoolLevelData();
  }, []);

  const columns = [
    {
      Header: "S.NO",
      accessor: "index",
      Cell: ({ row }: { row: any }) => row.index + 1,
      sort: true,
    },
    {
      Header: "Name",
      accessor: "name",
      sort: true,
    },
    {
      Header: "Email",
      accessor: "email",
      sort: true,
    },
    {
      Header: "Phone Number",
      accessor: "phone_number",
      sort: true,
    },
    {
      Header: "Query Coming From Level",
      accessor: "queryOfLevel",
      sort: true,
    },

    // {
    //   Header: "Query Subject",
    //   accessor: "subject",
    //   sort: true,
    // },

    {
      Header: "Query Subject",
      accessor: "subject",
      Cell: ({ value }: { value: string }) => (
        <div style={{ wordWrap: "break-word", whiteSpace: "pre-wrap" }}>
          {value}
        </div>
      ),
      sort: true,
    },
    // {
    //   Header: "Status",
    //   accessor: "status",
    //   //   Cell: ({ value }: { value: any }) => (
    //   //     <span>{value ? "Completed" : "Incomplete"}</span>
    //   //   ),
    //   sort: true,
    // },

    // {
    //   Header: "Status",
    //   accessor: "status",
    //   Cell: ({ value }: { value: string }) => (
    //     <span style={{ color: value === "Closed" ? "green" : "red" }}>
    //       {value === "Closed" ? "Completed" : value}
    //     </span>
    //   ),
    //   sort: true,
    // },

    {
      Header: "Status",
      accessor: "status",
      Cell: ({ value }: { value: string }) => {
        const getColor = (status: string) => {
          switch (status) {
            case "Closed":
              return "green";
            case "In-Review":
              return "blue";
            case "Open":
              return "red";
            case "Pending":
              return "#ebcf34";
            default:
              return "black";
          }
        };

        return (
          <span style={{ color: getColor(value), fontWeight: "bold" }}>
            {value}
          </span>
        );
      },
      sort: true,
    },

    {
      Header: "Details",
      accessor: "a",
      Cell: ({ row }: { row: any }) => (
        // <a href={`/details/1`}>
        //   <i className="uil uil-eye"></i>
        // </a>
        <QueryDetailsModal data={row.original} />
      ),
      sort: false,
    },
    // {
    //   Header: "Change Status",
    //   accessor: "b",
    //   Cell: ({ row }: { row: any }) => (
    //     <div>
    //       <select
    //        value={ row.original.status} // Default to current status
    //       // onChange={(e) => handleChangeStatus(e.target.value)} // Handle change
    //       >
    //         <option value="">Select Status</option>
    //         <option value="Open">Open</option>
    //         <option value="In-Review">In-Review</option>
    //         <option value="Pending">Pending</option>
    //         <option value="Closed">Closed</option>
    //       </select>
    //       <br />
    //       <Button>Set Status</Button>

    //     </div>
    //   ),
    //   sort: false,
    // },
  ];

  const sizePerPageList = [
    { text: "10", value: 10 },
    { text: "25", value: 25 },
    { text: "50", value: 50 },
    { text: "All", value: data?.length },
  ];

  const handleFilterQuery = async () => {
    try {
      if (!selectedLevel && !selectedStatusOfQuery) {
        alert("Pease select Level and Status");
      } else {
        setLoading(true);
        const response = await api.fetchData(
          `/api/filter/data/${selectedLevel}/${selectedStatusOfQuery}/query`
        );
        if (response?.status === 404) {
          setError(response?.message);
        }
        console.log("response query", response);
        setData(response?.data?.data);
        setLoading(false);
      }
    } catch (err) {
      console.log("Error", err);
    } finally {
      setLoading(false);
    }
  };
  const handleClearFilterQuery = () => {
    if (selectedLevel || selectedStatusOfQuery) {
      setSelectedLevel("");
      setSelectedStatusOfQuery("");
      functionToGetAllSchoolLevelData();
    }
  };

  useEffect(() =>{
    if (error) {
      setTimeout(() => {
        setError('')
      },2000)
    }
  },[error])

  return (
    <>
      <PageTitle
        breadCrumbItems={[
          { label: "All Queries", path: "/query" },
          {
            label: "Query",
            path: "/query",
            active: true,
          },
        ]}
        title={"All Query"}
      />

      <Row style={{ marginBottom: "1rem" }}>
        <Col sm={4}>
          <FormGroup>
            <FormLabel>Select Level</FormLabel>
            <FormControl
              as="select"
              value={selectedLevel}
              onChange={(e) => setSelectedLevel(e.target.value)}
            >
              <option value="">Select a Level</option>
              <option value="School">School</option>
              <option value="Block">Block</option>
              <option value="District">District</option>
              <option value="State">State</option>
            </FormControl>
          </FormGroup>
        </Col>
        <Col sm={4}>
          <FormGroup>
            <FormLabel>Select Status of Query</FormLabel>
            <FormControl
              as="select"
              value={selectedStatusOfQuery}
              onChange={(e) => setSelectedStatusOfQuery(e.target.value)}
            >
              <option value="">Select a Status</option>
              <option value="Open">Open</option>
              <option value="Closed">Closed</option>
              <option value="Pending">Pending</option>
              <option value="In-Review">In-Review</option>
            </FormControl>
          </FormGroup>
        </Col>
        <Col sm={4}>
          {error ? (
            <h3 style={{ backgroundColor: "silver", color: "black" }}>
              {error}
            </h3>
          ) : null}
          <Button
            style={{ marginTop: "1.8rem", marginRight: "1rem" }}
            onClick={handleFilterQuery}
            disabled={loading}
          >
            Filter
          </Button>
          <Button
            style={{ marginTop: "1.8rem", marginRight: "1rem" }}
            onClick={handleClearFilterQuery}
            disabled={loading}
          >
            Clear Filter
          </Button>
        </Col>
      </Row>
      <Row>
        <Col>
          <Card>
            <Card.Body>
              {loading ? (
                <div style={{ textAlign: "center" }}>
                  <Spinner />
                </div>
              ) : (
                <Table
                  columns={columns}
                  data={data ? data : []}
                  pageSize={10}
                  sizePerPageList={sizePerPageList}
                  isSortable={true}
                  pagination={true}
                  isSearchable={true}
                />
              )}
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default AllQuery;
