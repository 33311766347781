import { useEffect, useState } from "react";
import {
  Button,
  Col,
  FormControl,
  FormGroup,
  FormLabel,
  Modal,
  Row,
} from "react-bootstrap";
import styles from "./QueryDetailsModal.module.css";
import api from "../../../../utils/ApiMethod";
import { RootState } from "../../../../redux/store";
import { useSelector } from "react-redux";

const QueryDetailsModal = ({ data }: any) => {
  // console.log("attachement", data);
  const [show, setShow] = useState(false);
  const [textAreaForRemarks, setTextAreaForRemarks] = useState("");
  const [changeStatusOfQuery, setChangeStatusOfQuery] = useState(
    data?.status || ""
  );
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [emptyFieldMessage, setEmptyFieldMessage] = useState("");

  const showModal = () => {
    setShow(true);
  };
  const handleClose = () => {
    setShow(false);
  };

  const { user, userLoggedIn, error, _id } = useSelector(
    (state: RootState) => ({
      user: state.Auth.user,
      loading: state.Auth.loading,
      error: state.Auth.error,
      userLoggedIn: state.Auth.userLoggedIn,
      _id: state.Auth._id,
    })
  );

  let profileId = user._id ? user._id : user.user ? user.user._id : "";
  const Key_Key = user.moniterd_ttl
    ? user?.moniterd_ttl
    : user?.user
    ? user?.user?.moniterd_ttl
    : "";
  const handleUpdateQueryStatus = async () => {
    try {
      setLoading(true);
      if (textAreaForRemarks && changeStatusOfQuery) {
        const formData = new FormData();
        formData.append("updatedStatus", changeStatusOfQuery);
        formData.append("queryStatusBeingUpdatedBy", profileId);
        formData.append("remarks", textAreaForRemarks);
        const response = await api.updatePatchData(
          `/api/update/status/of/${data?._id}/query`,
          formData
        );
        setMessage(response?.data?.message);
        window.location.reload();
        if (response.status !== 200) {
          setErrorMessage(response?.message);
        }
      } else {
        setEmptyFieldMessage("Remarks is required.");
      }
    } catch (err) {
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  // const dateObj = new Date(lastUpdateAt);

  // Format date as dd-mm-yyyy
  // const formattedDate = `${String(dateObj.getDate()).padStart(2, '0')}-${String(dateObj.getMonth() + 1).padStart(2, '0')}-${dateObj.getFullYear()}`;

  // Format time as HH:mm:ss
  // const formattedTime = `${String(dateObj.getHours()).padStart(2, '0')}:${String(dateObj.getMinutes()).padStart(2, '0')}:${String(dateObj.getSeconds()).padStart(2, '0')}`;

  useEffect(() => {
    if (message || errorMessage) {
      setTimeout(() => {
        setMessage("");
        setErrorMessage("");
      }, 2500);
    }
  }, [message, errorMessage]);

  useEffect(() => {
    if (emptyFieldMessage) {
      setTimeout(() => {
        setEmptyFieldMessage("");
      }, 3000);
    }
  }, [emptyFieldMessage]);
  return (
    <div>
      <i
        className="uil uil-eye"
        onClick={showModal}
        title="Click to view details"
        style={{ cursor: "pointer" }}
      ></i>
      <Modal show={show} size="lg" centered>
        <Modal.Header>
          <strong>Query Details</strong>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col>
              <div style={{ width: "20rem" }}>
                <p className={styles.header}>
                  Name :<br />
                  <span>{data.name}</span>
                </p>
              </div>
              <div style={{ width: "20rem" }}>
                <p className={styles.header}>
                  Email :<br />
                  <span>{data.email}</span>
                </p>
              </div>
              <div style={{ width: "20rem" }}>
                <p className={styles.header}>
                  Mobile :<br />
                  <span>{data.phone_number}</span>
                </p>
              </div>
            </Col>
            <Col>
              <div style={{ width: "20rem" }}>
                <p className={styles.header}>
                  Current Status :<br />
                  <span
                    style={
                      data.status === "Open" || data.status === "Pending"
                        ? { color: "red" }
                        : { color: "green" }
                    }
                  >
                    {data.status}
                  </span>
                </p>
              </div>
              <div style={{ width: "20rem" }}>
                <p className={styles.header}>
                  Query Coming from level :<br />
                  <span>{data.queryOfLevel}</span>
                </p>
              </div>
              <div style={{ width: "20rem" }}>
                <p className={styles.header}>
                  Query Subject :<br />
                  <span>{data.subject}</span>
                </p>
              </div>
            </Col>
          </Row>
          <Row>
            <div>
              <p className={styles.header}>
                Query :<br />
                <span style={{ textAlign: "justify" }}>{data?.query}</span>
              </p>
            </div>
          </Row>
          <Row>
            <Col sm={6}>
              <div>
                <p className={styles.header}>
                  Query Created At:
                  <br />
                  <span style={{ textAlign: "justify" ,color:'green'}}>
                    {/* Convert createdAt string to Date object */}
                    {data?.createdAt ? (
                      <>
                        {/* Format the date as dd-mm-yyyy */}
                        {String(new Date(data?.createdAt).getDate()).padStart(
                          2,
                          "0"
                        )}
                        -
                        {String(
                          new Date(data?.createdAt).getMonth() + 1
                        ).padStart(2, "0")}
                        -{new Date(data?.createdAt).getFullYear()}
                      </>
                    ) : (
                      "Date not available"
                    )}
                  </span>
                </p>
              </div>
            </Col>
            <Col sm={6}>
              <div>
                <p className={styles.header}>
                  Query Last Update At:
                  <br />
                  <span style={{ textAlign: "justify" ,color:'green'}}>
                    {data?.remarks?.length > 0 ? (
                      <>
                        {String(
                          new Date(
                            data?.remarks[data?.remarks.length - 1]?.updatedAt
                          ).getDate()
                        ).padStart(2, "0")}
                        -
                        {String(
                          new Date(
                            data?.remarks[data?.remarks.length - 1]?.updatedAt
                          ).getMonth() + 1
                        ).padStart(2, "0")}
                        -
                        {new Date(
                          data?.remarks[data?.remarks.length - 1]?.updatedAt
                        ).getFullYear()}
                      </>
                    ) : (
                      "Date not available"
                    )}
                  </span>
                </p>
              </div>
            </Col>
          </Row>
          <Row>
            <strong>Attachment: </strong>
            <a
              href={data?.attachment || "#"} // Ensure the link is not broken
              target="_blank"
              rel="noopener noreferrer"
              style={{
                textDecoration: data?.attachment ? "none" : "line-through", // Strike-through to indicate disabled
                color: data?.attachment ? "blue" : "gray", // Gray color for disabled state
                pointerEvents: data?.attachment ? "auto" : "none", // Disable click events when attachment is null
              }}
            >
              Uploaded Query Attachment Link
            </a>
            {data?.attachment ? (
              <img
                src={data.attachment}
                width="700px"
                height="300px"
                alt="Uploaded Attachment of Query"
                style={{ marginTop: "10px" }}
              />
            ) : (
              <h4 style={{ color: "red" }}>No any attachement uploaded</h4>
            )}
          </Row>
          <Row>
            <Col sm={6}>
              <FormGroup>
                <FormLabel>Update Query Status</FormLabel>
                <FormControl
                  as="select"
                  value={changeStatusOfQuery}
                  onChange={(e) => setChangeStatusOfQuery(e.target.value)}
                >
                  <option value="">Select a Status</option>
                  <option value="Open">Open</option>
                  <option value="Closed">Closed</option>
                  <option value="Pending">Pending</option>
                  <option value="In-Review">In-Review</option>
                </FormControl>
              </FormGroup>
            </Col>
            {/* <Col sm={6}>
              <Button
                style={{ marginTop: "1.8rem" }}
                onClick={handleUpdateQueryStatus}
              >
                {loading ? "Updating status..." : "Update Status"}
              </Button>
              {message && (
                <p style={{ color: "green" }}>
                  <strong>{message}</strong>
                </p>
              )}
              {errorMessage && (
                <p style={{ color: "red" }}>
                  <strong>{errorMessage}</strong>
                </p>
              )}
            </Col> */}
          </Row>
          <Row>
            <Col sm={6} className="mt-1">
              <FormGroup>
                <FormLabel>Remarks: -</FormLabel>
                <FormControl
                  as="textarea"
                  value={textAreaForRemarks}
                  onChange={(e) => setTextAreaForRemarks(e.target.value)}
                  rows={4} // Adjust the number of rows as needed
                  placeholder="Enter your query remarks here..."
                  required
                />
                {emptyFieldMessage ? (
                  <h6 style={{ color: "red" }}>{emptyFieldMessage}</h6>
                ) : null}
              </FormGroup>
            </Col>
            <Col sm={6} className="mt-1">
              <Button
                style={{ marginTop: "1.8rem" }}
                onClick={handleUpdateQueryStatus}
              >
                {loading ? "Updating status..." : "Update Status & Remarks"}
              </Button>
              {message && (
                <p style={{ color: "green" }}>
                  <strong>{message}</strong>
                </p>
              )}
              {errorMessage && (
                <p style={{ color: "red" }}>
                  <strong>{errorMessage}</strong>
                </p>
              )}
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={handleClose}>Close</Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default QueryDetailsModal;
