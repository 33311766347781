import React, { useEffect, useState } from "react";
import {
  Modal,
  Button,
  Form,
  Container,
  Card,
  FormGroup,
  FormLabel,
  FormControl,
  Alert,
} from "react-bootstrap";
import api from "../../../../utils/ApiMethod";

const UpdateCrcModal = ({ data }: any) => {
  // console.log("data",data)
  const [showModal, setShowModal] = useState(false);
  const [crcName, setCrcName] = useState(data?.region_name || "");
  const [responseMessage, setResponseMessage] = useState("");
  const [updating, setUpdating] = useState(false);

  const handleShow = () => setShowModal(true);
  const handleClose = () => setShowModal(false);

  const handleUpdateCrcName = async (e: any) => {
    e.preventDefault();
    try {
      setUpdating(true);
      const formData = new FormData();
      formData.append("CrcName", crcName);
      formData.append("CrcId", data?._id);
      const response = await api.updatePatchData(
        `/api/update/crc/from/admin`,
        formData
      );
      console.log("response", response);
      if (response?.status === 200) {
        setResponseMessage(response?.data?.message);
      }
      setUpdating(false);
      if (response.status !== 200) {
        setResponseMessage(response?.message || "Error while updating CRC.");
        throw new Error(response?.message || "Error while updating CRC.");
      }
    } catch (error) {
      console.log("error while updating CRC");
    } finally {
      setUpdating(false);
    }
  };

  useEffect(() => {
    if (responseMessage) {
      setTimeout(() => {
        setResponseMessage("");
        window.location.reload();
      }, 3000);
    }
  }, [responseMessage]);

  return (
    <div>
      <Button variant="primary" onClick={handleShow}>
        Update CRC
      </Button>
      <Modal show={showModal} onHide={handleClose} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Update CRC</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {/* Form content */}
          <Container>
            {responseMessage && (
              <Alert className="mb-2 mt-2">{responseMessage}</Alert>
            )}
            <Card>
              <Card.Body>
                <Form>
                  <FormGroup>
                    <FormLabel>CRC Name :-</FormLabel>
                    <FormControl
                      type="text"
                      value={crcName}
                      onChange={(e) => setCrcName(e.target.value)}
                    ></FormControl>
                  </FormGroup>
                  <FormGroup className="mt-2">
                    <FormLabel>Block Name :-</FormLabel>
                    <FormControl
                      type="text"
                      value={data?.parent_region?.region_name}
                      readOnly
                    ></FormControl>
                  </FormGroup>
                </Form>
              </Card.Body>
            </Card>
          </Container>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={handleUpdateCrcName}>
            {updating ? "Updating..." : "Update CRC"}
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default UpdateCrcModal;
