import { useEffect, useState } from "react";
import { Button, Card, Col, Dropdown, Form, Row } from "react-bootstrap";
import api from "../../../../utils/ApiMethod";
import PageTitle from "../../../../components/PageTitle";
import Spinner from "../../../../components/Spinner";
import Table from "../../../../components/Table";
type RowIdType = string | number; // Define a type alias for row IDs
const SchoolNotResetTheirPassword = () => {
  const [data, setData] = useState<any>();
  console.log("vvvvvvvvvvvv9876", data);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [totaSchoolNotResetPassword, setTotalSchoolNotResetPassword] =
    useState<any>();

  const functionToGetAllSchoolLevelData = async () => {
    setLoading(true);
    setError(null);
    try {
      const response = await api.fetchDataAuth(`/school/not/reset/pass`);
      console.log("data", response);
      setData(response?.data?.schools);
      setTotalSchoolNotResetPassword(response?.data?.totalCount);
    } catch (error) {
      console.error("Error fetching data:", error);
      setError("Failed to fetch details. Please try again later.");
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    functionToGetAllSchoolLevelData();
  }, []);

  const columns = [
    {
      Header: "S.NO",
      accessor: "index",
      Cell: ({ row }: { row: any }) => row.index + 1,
      sort: true,
    },
    {
      Header: "Udise Code",
      accessor: "Udise_Code",
      sort: true,
    },
    {
      Header: "School Name",
      accessor: "School_Name",
      //   Cell: ({ row }:{row:any}) => row?.values?.parent_region?.region_name || "",
      //   Cell: ({ row }: { row: any }) =>
      //     row?.parent_region ? row?.parent_region?.region_name : "",
      sort: true,
    },
    {
      Header: "Block Name Code",
      accessor: "Block_Name_Code",
      sort: true,
    },
    {
      Header: "School District",
      accessor: "District_Name_Code",
      sort: true,
    },
    // {
    //   Header: "Mobile no.",
    //   accessor: "currentMobileNumuber",
    //   sort: true,
    // },

    {
      Header: "Status Of Password Reset",
      accessor: "passwordReset",
      sort: true,
      Cell: ({ row }: { row: any }) => {
        // Check the passwordReset field and display accordingly
        return row?.original?.passwordReset ? (
          <span style={{ color: "green", fontWeight: "bold" }}>
            Reseted Password
          </span>
        ) : (
          <span style={{ color: "red", fontWeight: "bold" }}>
            Password Not Reset
          </span>
        );
      },
    },

    // {
    //   Header: "Details",
    //   accessor: "a",
    //   Cell: () => (
    //     <a href={`/details/1`}>
    //       <i className="uil uil-eye"></i>
    //     </a>
    //   ),
    //   sort: false,
    // },
    // {
    //   Header: "Action",
    //   accessor: "b",
    //   //   Cell: ({row} : {row:any}) => (
    //   // <a href={`/edit/2`}>
    //   //   <i className="uil uil-edit"></i>
    //   // </a>
    //   // <UpdateCrcModal data={row.original}/>
    //   //   ),
    //   sort: false,
    // },
  ];

  const sizePerPageList = [
    { text: "10", value: 10 },
    { text: "25", value: 25 },
    { text: "50", value: 50 },
    { text: "All", value: data?.length },
  ];

  return (
    <>
      <PageTitle
        breadCrumbItems={[
          { label: "All School List Not reset their password", path: "/school/list" },
          {
            label: "All CRC list",
            path: "/school/list",
            active: true,
          },
        ]}
        title={"All School list"}
      />
      <Row>
        <Col>
          <Card>
            <Card.Body>
              <h3>Total Count of School Not reset their password:</h3>
              <span>
                <strong>
                  {totaSchoolNotResetPassword ? <div><u><span style={{color:'green',marginRight: '1rem'}}>{totaSchoolNotResetPassword}</span>Schools</u></div> : ""}
                </strong>
              </span>
            </Card.Body>
          </Card>
          <Card>
            <Card.Body>
              {loading ? (
                <div style={{ textAlign: "center" }}>
                  <Spinner />
                </div>
              ) : (
                <Table
                  columns={columns}
                  data={data ? data : []}
                  pageSize={10}
                  sizePerPageList={sizePerPageList}
                  isSortable={true}
                  pagination={true}
                  isSearchable={true}
                />
              )}
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default SchoolNotResetTheirPassword;
