import React, { useEffect, useState } from "react";
import {
  Alert,
  Button,
  Card,
  Container,
  Form,
  FormControl,
  FormGroup,
  FormLabel,
} from "react-bootstrap";
import PageTitle from "../../../../components/PageTitle";
import api from "../../../../utils/ApiMethod";

const AddNewCrc = () => {
  const [crcName, setCrcName] = useState("");
  const [blockName, setBlockName] = useState("");
  const [adding, setAdding] = useState(false);
  const [fieldRequired, setFieldRequired] = useState("");
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [responseMessage, setResponseMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const functionToGetAllBlockData = async () => {
    setLoading(true);
    try {
      const response = await api.fetchData("/api/block/list");
      console.log("data", response);
      if (response.status === 200) {
        setData(response.data.blocks);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    functionToGetAllBlockData();
  }, []);

  const handleAddNewCRC = async () => {
    try {
      setAdding(true);
      if (crcName && blockName) {
        const formData = new FormData();
        formData.append("CRCNameWithCode", crcName);
        formData.append("BlockNameWithCode", blockName);
        // formData.append("idOfSelectedBlock",_id)
        const response = await api.createData("/add/new/crc", formData);
        console.log("response", response);
        if (response.status === 200) {
          setResponseMessage(
            response?.data?.message || "New CRC Added Successfully."
          );
          setAdding(false);
        } else {
          setErrorMessage(
            response?.message || "Something went wrong while adding new CRC."
          );
          setAdding(false);
        }
      } else {
        setFieldRequired("All fields are required.");
        setAdding(false);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setAdding(false);
    }
  };

  useEffect(() => {
    if (fieldRequired) {
      setTimeout(() => {
        setFieldRequired("");
      }, 3000);
    }
    if(errorMessage){
        setTimeout(() =>{
            setErrorMessage('')
        },3000)
    }
    if(responseMessage){
        setTimeout(() => {
            setResponseMessage('')
        },3000)
    }
  }, [fieldRequired,errorMessage,responseMessage]);


  return (
    <div>
      <PageTitle
        breadCrumbItems={[
          { label: "Add New CRC", path: "/add/new/crc" },
          {
            label: "Add New CRC",
            path: "/add/new/crc",
            active: true,
          },
        ]}
        title={"Add New CRC"}
      />
      <Container>
        {fieldRequired && (
          <Alert style={{ color: "red" }}>{fieldRequired}</Alert>
        )}

        {responseMessage && <Alert variant="success">{responseMessage}</Alert>}
        {errorMessage && <Alert variant="danger">{errorMessage}</Alert>}

        <Card>
          <Card.Body>
            <Form>
              <FormGroup>
                <FormLabel>CRC Name with Code:</FormLabel>
                <span style={{ color: "red" }}>*</span>
                <FormControl
                  type="text"
                  value={crcName}
                  onChange={(e) => setCrcName(e.target.value)}
                ></FormControl>
              </FormGroup>
              <FormGroup className="mt-2">
                <FormLabel>Select Your Block:</FormLabel>
                <span style={{ color: "red" }}>*</span>
                <FormControl
                  as="select"
                  value={blockName}
                  onChange={(e) => setBlockName(e.target.value)}
                >
                  <option value="">Select Block</option>
                  {loading ? (
                    <option value="">Loading...</option>
                  ) : (
                    data?.map((item: any) => (
                      <option value={item?.region_name} key={item?._id}>
                        {item?.region_name}
                      </option>
                    ))
                  )}
                </FormControl>
              </FormGroup>
            </Form>
          </Card.Body>
          <Card.Footer>
            <Button onClick={handleAddNewCRC}>
              {adding ? "Adding CRC..." : "Add New CRC"}
            </Button>
          </Card.Footer>
        </Card>
      </Container>
    </div>
  );
};

export default AddNewCrc;
