import { useEffect, useState } from "react";
import { Button, Card, Col, Dropdown, Form, Row } from "react-bootstrap";
import api from "../../../utils/ApiMethod";
import PageTitle from "../../../components/PageTitle";
import Spinner from "../../../components/Spinner";
import Table from "../../../components/Table";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
type RowIdType = string | number; // Define a type alias for row IDs
const ResolvedQueryByAdmin = () => {
  const {
    user,
    userLoggedIn,
    // loading, error,
    userId,
  } = useSelector((state: RootState) => ({
    user: state.Auth.user,
    loading: state.Auth.loading,
    error: state.Auth.error,
    userLoggedIn: state.Auth.userLoggedIn,
    userId: state.Auth._id,
  }));
  let profileId = user._id ? user._id : user.user ? user.user._id : "";
  //   console.log("profileId",profileId)
  const Key_Key = user.moniterd_ttl
    ? user.moniterd_ttl
    : user.user
    ? user.user.moniterd_ttl
    : "";
  const [data, setData] = useState<any>();
  console.log("vvvvvvvvvvvv9876", data);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [totalResolvedQueryCount, setTotalQueriesResolved] = useState<any>();

  const functionToGetAllSchoolLevelData = async () => {
    setLoading(true);
    setError(null);
    try {
      const response = await api.fetchDataAuth(
        `/admin/get/all/queries/${profileId}`
      );
      console.log("data", response);
      setData(response?.data?.queries);
      setTotalQueriesResolved(response?.data?.totalResovedQueries);
    } catch (error) {
      console.error("Error fetching data:", error);
      setError("Failed to fetch details. Please try again later.");
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    functionToGetAllSchoolLevelData();
  }, []);

  const columns = [
    {
      Header: "S.NO",
      accessor: "index",
      Cell: ({ row }: { row: any }) => row.index + 1,
      sort: true,
    },
    {
      Header: "Sender's Name",
      accessor: "name",
      sort: true,
    },
    // {
    //   Header: "Query Subject",
    //   accessor: "subject",
    //   //   Cell: ({ row }:{row:any}) => row?.values?.parent_region?.region_name || "",
    //   //   Cell: ({ row }: { row: any }) =>
    //   //     row?.parent_region ? row?.parent_region?.region_name : "",
    //   sort: true,
    // },

    // {
    //   Header: "Email",
    //   accessor: "email",
    //   sort: true,
    // },
    {
      Header: "Phone Number",
      accessor: "phone_number",
      sort: true,
    },
    {
      Header: "Query Coming From Level",
      accessor: "queryOfLevel",
      sort: true,
    },
    {
      Header: "Query Subject",
      accessor: "subject",
      Cell: ({ value }: { value: string }) => (
        <div style={{ wordWrap: "break-word", whiteSpace: "pre-wrap" }}>
          {value}
        </div>
      ),
      sort: true,
    },
    {
        Header: "Status",
        accessor: "status",
        Cell: ({ value }: { value: string }) => {
          const getColor = (status: string) => {
            switch (status) {
              case "Closed":
                return "green";
              case "In-Review":
                return "blue";
              case "Open":
                return "red";
              case "Pending":
                return "#ebcf34";
              default:
                return "black";
            }
          };
  
          return (
            <span style={{ color: getColor(value), fontWeight: "bold" }}>
              {value}
            </span>
          );
        },
        sort: true,
      },
    // {
    //   Header: "Block Name Code",
    //   accessor: "Block_Name_Code",
    //   sort: true,
    // },
    // {
    //   Header: "School District",
    //   accessor: "District_Name_Code",
    //   sort: true,
    // },

    // {
    //   Header: "Status Of Password Reset",
    //   accessor: "passwordReset",
    //   sort: true,
    //   Cell: ({ row }: { row: any }) => {
    //     // Check the passwordReset field and display accordingly
    //     return row?.original?.passwordReset ? (
    //       <span style={{ color: "green", fontWeight: "bold" }}>
    //         Reseted Password
    //       </span>
    //     ) : (
    //       <span style={{ color: "red", fontWeight: "bold" }}>
    //         Password Not Reset
    //       </span>
    //     );
    //   },
    // },

    // {
    //   Header: "Details",
    //   accessor: "a",
    //   Cell: () => (
    //     <a href={`/details/1`}>
    //       <i className="uil uil-eye"></i>
    //     </a>
    //   ),
    //   sort: false,
    // },
    // {
    //   Header: "Action",
    //   accessor: "b",
    //   //   Cell: ({row} : {row:any}) => (
    //   // <a href={`/edit/2`}>
    //   //   <i className="uil uil-edit"></i>
    //   // </a>
    //   // <UpdateCrcModal data={row.original}/>
    //   //   ),
    //   sort: false,
    // },
  ];

  const sizePerPageList = [
    { text: "10", value: 10 },
    { text: "25", value: 25 },
    { text: "50", value: 50 },
    { text: "All", value: data?.length },
  ];

  return (
    <>
      <PageTitle
        breadCrumbItems={[
          { label: `Query Resoved By`, path: "/school/list" },
          {
            label: `Query Resoved By`,
            path: "/school/list",
            active: true,
          },
        ]}
        title={`Query Resoved By`}
      />
      <Row>
        <Col>
          <Card>
            <Card.Body>
              <h3>Total Query resolved by:</h3>
              <span>
                <strong>
                  {totalResolvedQueryCount ? (
                    <div>
                      <u>
                        <span style={{ color: "green", marginRight: "1rem" }}>
                          {totalResolvedQueryCount}
                        </span>
                        Queries
                      </u>
                    </div>
                  ) : (
                    ""
                  )}
                </strong>
              </span>
            </Card.Body>
          </Card>
          <Card>
            <Card.Body>
              {loading ? (
                <div style={{ textAlign: "center" }}>
                  <Spinner />
                </div>
              ) : (
                <Table
                  columns={columns}
                  data={data ? data : []}
                  pageSize={10}
                  sizePerPageList={sizePerPageList}
                  isSortable={true}
                  pagination={true}
                  isSearchable={true}
                />
              )}
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default ResolvedQueryByAdmin;
